import { createRouter, createWebHistory } from "vue-router";
import LandingLayout from "@/components/layouts/LandingLayout.vue";
import ClearLayout from "@/components/layouts/ClearLayout.vue";
import AuthLayout from "@/components/layouts/AuthLayout.vue";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: ClearLayout,
    beforeEnter: (to, from, next) => {
      next({ name: "HomeView" });
    },
  },

  {
    path: "/r/:id",
    component: () =>
      import(
        /* webpackChunkName: "ReferralRedirect" */ "../views/ReferralRedirect.vue"
      ),
  },

  {
    path: "/",
    component: LandingLayout,

    children: [
      {
        path: "",
        name: "HomeView",
        component: () =>
          import(/* webpackChunkName: "HomeView" */ "../views/HomeView.vue"),
      },
      {
        path: "teacher",
        component: ClearLayout,
        children: [
          {
            path: "",
            component: ClearLayout,
            beforeEnter: (to, from, next) => {
              if (localStorage.token) {
                next({ name: "PanelCatalog" });
                return;
              }

              next({ name: "HomeView" });
            },
          },
          {
            path: ":id",
            component: ClearLayout,
            children: [
              {
                path: "",
                name: "TeacherPublicView",
                component: () =>
                  import(
                    /* webpackChunkName: "TeacherView" */ "../views/Teacher/TeacherView.vue"
                  ),
              },

              {
                path: "courses",
                name: "TeacherPublicCourses",
                component: () =>
                  import(
                    /* webpackChunkName: "TeacherCourses" */ "../views/Teacher/TeacherCourses.vue"
                  ),
              },

              {
                path: "achievements",
                name: "TeacherPublicAchievements",
                component: () =>
                  import(
                    /* webpackChunkName: "TeacherAchievements" */ "../views/Teacher/TeacherAchievements.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "/catalog",
    component: LandingLayout,

    children: [
      {
        path: "",
        name: "CoursesView",
        component: () =>
          import(
            /* webpackChunkName: "CoursesView" */ "../views/Courses/CoursesView.vue"
          ),
      },
      {
        path: ":id",
        name: "CourseView",
        component: () =>
          import(
            /* webpackChunkName: "CoursesView" */ "../views/Courses/CourseView.vue"
          ),
      },
    ],
  },

  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "AuthView",
        component: () =>
          import(/* webpackChunkName: "AuthView" */ "../views/AuthView.vue"),
      },
    ],
  },

  {
    path: "/documents",
    component: LandingLayout,
    children: [
      {
        path: "",
        component: ClearLayout,
        beforeEnter: (to, from, next) => {
          next({ name: "HomeView" });
        },
      },
      {
        path: "license-agreement",
        name: "LicenseAgreementView",
        component: () =>
          import(
            /* webpackChunkName: "LicenseAgreement" */ "../views/Documents/LicenseAgreementView.vue"
          ),
      },
      {
        path: "privacy-policy",
        name: "PrivacyPolicyView",
        component: () =>
          import(
            /* webpackChunkName: "PrivacyPolicyView" */ "../views/Documents/PrivacyPolicyView.vue"
          ),
      },
      {
        path: "offer",
        name: "OfferView",
        component: () =>
          import(
            /* webpackChunkName: "OfferView" */ "../views/Documents/OfferView.vue"
          ),
      },
    ],
  },

  {
    path: "/token/login",
    component: () =>
      import(
        /* webpackChunkName: "TokenAuthView" */ "../views/TokenAuthView.vue"
      ),
  },

  {
    path: "/oauth/login",
    name: "OAuthLogin",
    component: () =>
      import(
        /* webpackChunkName: "TokenAuthView" */ "../views/TokenAuthView.vue"
      ),
  },

  {
    path: "/panel",
    component: () =>
      import(
        /* webpackChunkName: "PanelLayout" */ "../components/layouts/PanelLayout.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!localStorage.token) {
        if (to.query.phone) {
          localStorage.setItem("authPhone", to.query.phone);
        }
        next({ name: "AuthView" });
        return;
      }

      next();
    },
    children: [
      {
        path: "",
        component: ClearLayout,
        beforeEnter: (to, from, next) => {
          next({ name: "PanelCatalog" });
        },
      },
      {
        path: "profile",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "ProfileView",
            component: () =>
              import(
                /* webpackChunkName: "ProfileVie" */ "../views/Panel/Profile/ProfileView.vue"
              ),
          },
          {
            path: "edit",
            name: "ProfileEdit",
            component: () =>
              import(
                /* webpackChunkName: "ProfileEdit" */ "../views/Panel/Profile/ProfileEdit.vue"
              ),
          },
          {
            path: "referral",
            name: "ReferralView",
            component: () =>
              import(
                /* webpackChunkName: "ReferralView" */ "../views/Panel/Profile/ReferralView.vue"
              ),
          },
        ],
      },
      {
        path: "catalog",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "PanelCatalog",
            component: () =>
              import(
                /* webpackChunkName: "ProfileVie" */ "../views/Courses/CoursesView.vue"
              ),
          },
          {
            path: ":id",
            name: "PanelCatalogCourse",
            component: () =>
              import(
                /* webpackChunkName: "ProfileVie" */ "../views/Courses/CourseView.vue"
              ),
          },
        ],
      },
      {
        path: "courses",
        component: ClearLayout,
        children: [
          {
            path: "",
            name: "PanelCourses",
            component: () =>
              import(
                /* webpackChunkName: "ProfileVie" */ "../views/Panel/CoursesView.vue"
              ),
          },
          {
            path: ":id",
            component: ClearLayout,

            children: [
              {
                path: "",
                name: "PanelCourse",
                component: () =>
                  import(
                    /* webpackChunkName: "ProfileEdit" */ "../views/Courses/CourseView.vue"
                  ),
              },
              {
                path: "lesson",
                component: ClearLayout,
                children: [
                  {
                    path: "",
                    component: ClearLayout,
                    beforeEnter: (to, from, next) => {
                      next({
                        name: "PanelCourse",
                        params: { id: to.params.id },
                      });
                    },
                  },
                  {
                    path: ":lessonId",
                    children: [
                      {
                        path: "",
                        name: "LessonView",
                        component: () =>
                          import(
                            /* webpackChunkName: "LessonView" */ "../views/Panel/LessonView.vue"
                          ),
                      },
                      {
                        path: "test",
                        name: "LessonTestView",
                        component: () =>
                          import(
                            /* webpackChunkName: "LessonTestView" */ "../views/Panel/LessonTestView.vue"
                          ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "teacher",
        component: ClearLayout,
        children: [
          {
            path: "",
            component: ClearLayout,
          },
          {
            path: ":id",
            component: ClearLayout,
            children: [
              {
                path: "",
                name: "TeacherView",
                component: () =>
                  import(
                    /* webpackChunkName: "TeacherView" */ "../views/Teacher/TeacherView.vue"
                  ),
              },

              {
                path: "courses",
                name: "TeacherCourses",
                component: () =>
                  import(
                    /* webpackChunkName: "TeacherCourses" */ "../views/Teacher/TeacherCourses.vue"
                  ),
              },

              {
                path: "achievements",
                name: "TeacherAchievements",
                component: () =>
                  import(
                    /* webpackChunkName: "TeacherAchievements" */ "../views/Teacher/TeacherAchievements.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "/webinars/:id",
    component: () =>
      import(
        /* webpackChunkName: "WebinarLayout" */ "../components/layouts/WebinarLayout.vue"
      ),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "WebinarView" */ "../views/WebinarView.vue"
          ),
        name: "WebinarView",
      },
    ],
  },
];

const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
